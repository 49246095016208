import React from "react"
import { Component, Fragment } from "react"
// import Panel from "./component/panel"
import CTA from "../../../general/cta"
import { scrollToElement } from "../../../../../utility/utils"
import TitleButton from "../../../general/titleButton"
import { Link } from "gatsby"

class FaqsOpenApi extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: 0,
            panelData: [],
            faqs: null,
            getLocalFaqs: [],
        }
        this.activateTab = this.activateTab.bind(this)
    }

    activateTab(index) {
        this.setState(prev => ({
            activeTab: prev.activeTab === index ? -1 : index,
        }))
    }

    getdata = async firebase => {
        const data = await firebase.FireBase()
        this.setState({
            getLocalFaqs: firebase.getFaqs(),
        })
        return data
    }

    componentDidMount = async () => {
        setTimeout(() => {
            scrollToElement()
            window.addEventListener("scroll", function () {
                scrollToElement()
            })
        }, 0)

        import("../../../../../../firebase").then(firebase => {
            this.getdata(firebase)
        })

        this.setState({
            faqs: localStorage.getItem("kudaFaqs"),
        })
    }

    panels = []
    render() {
        if (this.state.faqs !== null) {
            this.panels = JSON.parse(this.state.faqs)
        } else {
            this.panels = this.state.getLocalFaqs
        }

        return (
            <Fragment>
                <div className="kuda-inner--heading kuda-disclaimer--section">
                    <div className="kuda-section--inner general-style">
                        <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                            <Link to="/en-ng/legal/" className="color-black mb-3 mt-4 back-page">
                                <div className="align-self-center mr-2">
                                    <div className="f-14">
                                        Legal
                                    </div>
                                </div>
                                <span className="mr-2">
                                    <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                            fill="#C7C7CC"
                                        ></path>
                                    </svg>
                                </span>
                                <span className="align-self-center f-14 mr-2">Nigeria</span>
                                <span className="mr-2">
                                    <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                            fill="#C7C7CC"
                                        ></path>
                                    </svg>
                                </span>
                                <span className="align-self-center f-14 text-bold text-left">Frequently Asked Questions</span>
                            </Link>
                            <div className="legal-left">
                                <h1 className="kuda-section--heading text-lg-biz mb-0 text-xlbold color-primary title-bottom--spacing no-margins">
                                    Open API FAQ
                                </h1>
                            </div>
                        </div>
                        <div className="kuda-section--100 kuda-spacing--inner">
                            <p className="color-primary text-bold bottom--spacing">
                                How does open API work?
                            </p>
                            <p className="color-black bottom--spacing">
                                Open API provides 3rd party access to the kuda internal system by using a series of protocols, a client_id, public_key, private_key.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                How secure is the Open API?
                            </p>
                            <p className="color-black bottom--spacing">
                                The Open API is very secure, before data is sent across the system, developers are expected to encrypt their data in a request to us using a protocol, and decrypt our response using the same protocols.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                What is Open API?
                            </p>
                            <p className="color-black bottom--spacing">
                                Open API is a collection of API services custom built for businesses who want to layer financial services onto their products/businesses offerings. The open api is built on kuda payment rails, it will enable businesses to do transfers, open virtual accounts and check balances programmatically.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                How do I register a profile to start using the APIs?
                            </p>
                            <p className="color-black bottom--spacing">
                                To begin using the Kuda APIs, you will need to register a profile {" "}
                                <a
                                    href="https://developer.kuda.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="color-primary text-bold link-decoration cursor-pointer"
                                >
                                    here
                                </a>.
                                Once you register, you can start testing out the APIs in our sandbox.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                What sort of documents do I need to start using the APIs?
                            </p>
                            <p className="color-black bottom--spacing">
                                You will need to be able to provide evidence of a business registration and address, utility bills and all that we can use to do a complete kyc or kyb of your business.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                How do I access the set of services and API offerings
                            </p>
                            <p className="color-black bottom--spacing">
                                To access the APIs, you will need to read through our documentation <a
                                    href="https://kudabank.gitbook.io/kudabank/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="color-primary text-bold link-decoration cursor-pointer"
                                >
                                    here
                                </a>.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                I have an app and I want to connect the API to test out my product. What do I need?
                            </p>
                            <p className="color-black bottom--spacing">
                                We do not have an option to allow developers to “test out applications”. Alternatively, you can register a profile and test out your app in our sandbox.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                Must I be registered in Nigeria to start using the APIs?
                            </p>
                            <p className="color-black bottom--spacing">
                                For the purpose of doing a full kyc/kyb, it is required that a business is registered in the country where the APIs are domiciled
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                Can I build a remittance product with the APIs?
                            </p>
                            <p className="color-black bottom--spacing">
                                We provide APIs to allow business layer financial services on top of their businesses/products. The APIs may allow you to layer a disbursement service model as long as you are a registered Nigerian company.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                Are there any charges while using the APIs?
                            </p>
                            <p className="color-black bottom--spacing">
                                At the moment, we charge for transfers (NIP transfers, transferring from Kuda to another bank)
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                Are there any limitations while using the APIs?
                            </p>
                            <p className="color-black bottom--spacing">
                                There are really no limitations when using the APIs. We have set regulations on transfers out of a kuda virtual account.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                How do I go live?
                            </p>
                            <p className="color-black bottom--spacing">
                                Once you are done testing out the processes of the APIs, you will need to connect your Kuda business account number and then toggle to live.
                            </p>
                            <p className="color-primary text-bold bottom--spacing">
                                How do I get a Kuda for Business Account number?
                            </p>
                            <p className="color-black bottom--spacing">
                                Please visit the Kuda for business <a
                                    href="https://kudabank.gitbook.io/kudabank/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="color-primary text-bold link-decoration cursor-pointer"
                                >
                                    website
                                </a> to register your business and obtain a business account number.
                            </p>

                            {/* <div className="accordion" role="tablist">
                {this.panels.map((panel, index) => (
                  <Panel
                    key={index}
                    activeTab={activeTab}
                    index={index}
                    {...panel}
                    activateTab={this.activateTab.bind(null, index)}
                  />
                ))}
              </div> */}
                            <TitleButton
                                text="Don't see your question?"
                                button="Visit our Help Center"
                                url="https://help.kuda.com/support/solutions/articles/73000559888-faqs"
                                isInnerSection={true}
                            />
                        </div>
                    </div>
                </div>
                <CTA />
            </Fragment>
        )
    }
}
export default FaqsOpenApi
